import React from "react";

export default class ViewItem extends React.Component {
  render() {
    return <></>;
  }
  componentDidMount() {
    const { props } = this.props;

    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_id: props.id,
            item_name: props.name,
            price: parseFloat(
              Number(props.currencyPrice.replace("€", "")).toFixed(2)
            ),
            item_brand: "Shroom",
            item_category: props.productCategories
              ? props.productCategories.nodes[0].name
              : "",
          },
        ],
        currency: "EUR",
      },
    });
  }
}
