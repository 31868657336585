import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import AddToCartButton from "../cart/add-to-cart-button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { isEmpty } from "lodash";
import SocialShareCard from "../social-share-card";
import ProductCarousel from "../product-carousel";
import SliderDrinks from "../slider-drinks";
import { sanitize } from "../../utils/functions";
// import ReactStars from "react-rating-stars-component"
import { Link } from "gatsby";
import ViewItem from "../data-layer/view-item";

const productImagePlaceholder = "https://via.placeholder.com/434";

const SingleProduct = (props) => {
  const { product } = props;
  // console.log(props)
  const [vId, setvId] = useState(null);
  // console.log(vId);
  const hasImagesSizes =
    !isEmpty(product.image) && !isEmpty(product.image.mediaDetails.sizes);
  const imgSrcUrl = hasImagesSizes ? product.image.sourceUrl : "";

  const displayProductImages = () => {
    if (!isEmpty(product.galleryImages.nodes)) {
      return <ProductCarousel galleryImages={product.galleryImages} />;
    } else if (!isEmpty(product.image)) {
      return (
        <figure>
          <LazyLoadImage
            alt={product.image.altText ? product.image.altText : ""}
            src={imgSrcUrl} // use normal <img> attributes as props
            effect="blur"
          />
        </figure>
      );
    } else if (!isEmpty(productImagePlaceholder)) {
      return (
        <figure>
          <LazyLoadImage
            alt="default"
            height="450"
            src={productImagePlaceholder}
            width="450"
            effect="blur"
          />
        </figure>
      );
    } else {
      return null;
    }
  };

  // VARIABLES GRIND

  useEffect(() => {
    if (product.variations) {
      setVarId();
    }
    if (product.translationsUrls[1] !== undefined) {
      document.getElementById("lang-switch").href = product.translationsUrls[1];
    }
  });
  let output;
  if (product.variations) {
    //console.log(product.variations)
    let varvalues = [];

    product.variations.nodes.forEach((item) => {
      varvalues.push(item.attributes.nodes);
    });
    //console.log(varvalues)

    const transformedArray = [];
    // Iterate through each sub-array in the original array
    for (const subArray of varvalues) {
      // Iterate through objects in the sub-array
      for (const obj of subArray) {
        const { label, value } = obj;
        // Check if the label already exists in the transformedArray
        const existingItem = transformedArray.find(
          (item) => item.label === label
        );
        if (existingItem) {
          // If the label exists, push the value to its "value" array
          existingItem.value.push(value);
        } else {
          // If the label doesn't exist, create a new object and push it to the transformedArray
          transformedArray.push({ label, value: [value] });
        }
      }
    }

    output = transformedArray;
  } else {
    output = null;
    product.variation = null;
    //console.log(product.variation)
    //setvId(null)
  }

  const setVarId = () => {
    //console.dir(product.variations)
    let selectedVars = [];
    for (
      let i = 0;
      i < document.getElementsByClassName("variation-s").length;
      i++
    ) {
      let vobj = { label: "", value: "" };
      //console.log(document.getElementsByClassName('variation-s')[i].value)

      //console.log(document.getElementsByClassName('variation-s')[i].previousSibling.innerHTML.replace(':',''))
      vobj.label = document
        .getElementsByClassName("variation-s")
        [i].previousSibling.innerHTML.replace(":", "");
      //console.log(document.getElementsByClassName('variation-s')[i].value)
      vobj.value = document.getElementsByClassName("variation-s")[i].value;
      selectedVars.push(vobj);
    }
    //console.log(selectedVars)
    //console.log(product.variations.nodes[0])
    product.variations.nodes.forEach((item, i) => {
      //console.log(product.variations.nodes[i].attributes)
      //console.log(JSON.stringify(item.attributes.nodes) === JSON.stringify(selectedVars))
      if (
        JSON.stringify(item.attributes.nodes) === JSON.stringify(selectedVars)
      ) {
        product.variation = product.variations.nodes[i];
        //console.log(product.variation)
        setvId(product.variation.databaseId);
        //console.log(vId)
      }
    });
  };

  return (
    // @TODO Need to handle Group products differently.
    !isEmpty(product) && "GroupProduct" !== product.nodeType ? (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`{
	      "@context": "https://schema.org/",
	      "@type": "Product",
	      "name": "${product.name}",
	      "image": [
	        "${imgSrcUrl}"
	       ],
	      "description": "${
          sanitize(product.description.slice(0, 200)).replace(
            /<[^>]*>?/gm,
            ""
          ) + "..."
        }",
	      "sku": ${product.sku ? product.sku : null},
	      "brand": {
	        "@type": "Brand",
	        "name": "Shroom"
	      },
	      "offers": {
	        "@type": "Offer",
	        "url": "${"https://shop.shroom4you.com" + product.link}",
	        "priceCurrency": "EUR",
	        "price": ${Number(product.currencyPrice.replace("€", "")).toFixed(2)},
	        "availability": "https://schema.org/InStock"
	      }
				}`}
          </script>
          <link
            rel="canonical"
            href={"https://shop.shroom4you.com" + product.link}
          />
        </Helmet>
        <ViewItem props={product} />
        <div className="single-product-page container py-5">
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-5 product-image-wrap">
              <div className="product-image">{displayProductImages()}</div>
            </div>
            <div className="col-lg-7 col-md-6 mb-5">
              <div className="single-product-desc">
                <h1 className="spec-h">{product.name ? product.name : ""}</h1>
                <Link
                  style={{ fontSize: "12px", margin: "15px 0px" }}
                  to={product.productCategories.nodes[0].uri}
                >
                  {product.productCategories.nodes[0].name}
                </Link>
                <br />
                <br />
                {!isEmpty(product.description) ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(product.description),
                    }}
                  />
                ) : null}
                <div className="single-product-add-to-cart">
                  <p className="card-subtitle mb-3">
                    {product.variations ? (
                      <>
                        {output ? (
                          <>
                            <br />
                            {output.map((val, i) => {
                              return (
                                <>
                                  <label
                                    className="label"
                                    style={{ marginRight: "10px" }}
                                  >
                                    {val.label}:
                                  </label>
                                  <select
                                    onInput={setVarId}
                                    onBlur={setVarId}
                                    className={"select variation-s " + i}
                                    style={{ marginRight: "20px" }}
                                  >
                                    {[...new Set(val.value)].map((item, i) => {
                                      return item !== "" ? (
                                        <option id={item} value={item}>
                                          {item}
                                        </option>
                                      ) : null;
                                    })}
                                  </select>
                                </>
                              );
                            })}
                          </>
                        ) : null}
                        <br />
                      </>
                    ) : null}

                    <br />

                    {product.price
                      ? product.price +
                        " | " +
                        Number(product.currencyPrice.replace("€", "")).toFixed(
                          2
                        ) +
                        "€"
                      : Number(
                          product.subscriptionPrice.replace("zł", "")
                        ).toFixed(2) +
                        "zł | " +
                        Number(product.currencyPrice.replace("€", "")).toFixed(
                          2
                        ) +
                        "€"}
                  </p>

                  <small className="card-subtitle text-success">
                    {product.stockQuanity > 0
                      ? product.nodeType === "P24_subscription"
                        ? "Subscription for: " +
                          product.subscriptionDays +
                          " days."
                        : "Available in stock: " + product.stockQuanity
                      : "Delivery may take up to 31 days."}
                  </small>
                  <br />
                  <br />
                  <AddToCartButton product={product} />
                </div>
                <SocialShareCard
                  title={product.name}
                  sectionTitle="Share this product:"
                  link={product.uri}
                />
                {/*<small style={{textAlign:'center'}}>Rate our product:</small>
            <div>
              <ReactStars
                 count={5}
                 onChange={()=>{document.getElementById('rating-dialog').style.display="block"}}
                 size={27}
                 activeColor="#DEA5CB"
               />
            </div>*/}
                <small
                  id="rating-dialog"
                  className="text-success"
                  style={{ display: "none" }}
                >
                  Thank you for your rating!
                </small>
                {product.productTags.nodes.length ? (
                  <>
                    <div>
                      <small>Tags:</small>
                      <p style={{ display: "flex", flexWrap: "wrap" }}>
                        {product.productTags.nodes.map((post) => {
                          return (
                            <>
                              &nbsp;
                              <small
                                style={{
                                  border: "1px solid #111",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  margin: "15px 5px",
                                }}
                              >
                                {post.name}
                              </small>
                              &nbsp;
                            </>
                          );
                        })}
                      </p>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            {!isEmpty(product.shortDescription) ? (
              <>
                <div
                  className="more-info"
                  style={{ padding: "1.5em" }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(product.shortDescription),
                  }}
                />
              </>
            ) : null}
          </div>
        </div>
        <div style={{ backgroundColor: "#e8d3d0" }}>
          <br />
          <br />
          <SliderDrinks />
          <br />
          <br />
          <p style={{ textAlign: "center", marginTop: "30px" }}>
            <Link className="btn btn-outline-primary" to="/#archive">
              Return to shop...
            </Link>
          </p>
          <br />
          <br />
        </div>
      </>
    ) : null
  );
};

export default SingleProduct;
